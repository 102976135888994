import React from 'react';
import '../components/cores.css';

const Title = ({ titulo }) => {
  const renderTextoComCor = (texto) => {
    return texto.split('').map((char, index) => {
      if (char.toLowerCase() === 'o') {
        return <span key={index} style={{ color: 'var(--verde)' }}>{char}</span>;
      }
      return char;
    });
  };

  return (
    <div className='titulo'>
      <h1>
        {renderTextoComCor(titulo)}
      </h1>
    </div>

  );
};

export default Title;
