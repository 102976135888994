import React from 'react';

const Editavel = ({ subtitulo, texto }) => {
  return (
    <div>
      <h2 style={{ fontSize: '40px', fontWeight: 'bold', marginBottom: '10px' }}>{subtitulo}</h2>
      <p style={{ fontSize: '15px',maxWidth:'500px' }}>{texto}</p>
    </div>
  );
};

export default Editavel;
