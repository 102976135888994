import React from "react";
import Editavel from "./texto-editável";
import BotaoNavegacao from "./Botão";

const CustomizableBox = ({
  width = "200px",
  height = "200px",
  backgroundColor = "#ddd",
  logo = "Minha Logo",
  logoColor = "#333",
  subtitulo = "",
  texto = "Texto Padrão",
  botaoTexto = "Clique Aqui",
  botaoDestino = "/",
  botaoImagem = null,
  botaoLargura = "150px",
  botaoAltura = "50px",
}) => {
  const boxStyle = {
    width,
    height,
    backgroundColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    padding: "1px",
    marginBottom: "20px", // Espaço para o botão
  };

  const logoStyle = {
    color: logoColor,
    fontSize: "20px",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={boxStyle}>
        <div className="logotipo" style={logoStyle}>
          {logo}
        </div>
        <div
          className="conteudo"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            padding: "10px",
            boxSizing: "border-box",
          }}
        >
          <Editavel subtitulo={subtitulo} texto={texto} />
        </div>
      </div>
      {/* Botão abaixo da box */}
      <BotaoNavegacao
        texto={botaoTexto}
        destino={botaoDestino}
        imagem={botaoImagem}
        largura={botaoLargura}
        altura={botaoAltura}
      />
    </div>
  );
};

export default CustomizableBox;
