import React from 'react';
import Botao from '../components/Botão';
import './Faca_parte.css';

const Cooperativa = () => {
    return (
        <div className="cooperativa-container">
            <header className="cooperativa-header">
                <h1>Faça Parte da Cooperbem</h1>
            </header>

            <section className="cooperativa-content">
                <h2>Como se tornar um cooperado?</h2>
                <p>
                    Para fazer parte da nossa cooperativa, siga os passos abaixo:
                </p>
                <ol>
                    <li>Entre em contato conosco pelo formulário ou telefone.</li>
                    <li>Agende uma reunião para conhecer mais sobre a cooperativa.</li>
                    <li>Traga a documentação necessária (RG, CPF, comprovante de residência).</li>
                    <li>Participe da nossa integração de novos cooperados.</li>
                </ol>
                <Botao texto="QUERO FAZER PARTE" onClick={() => alert('Inscrição iniciada!')} />
            </section>

            <footer className="cooperativa-footer">
                <p>© 2024 Cooperbem. Todos os direitos reservados.</p>
            </footer>
        </div>
    );
};

export default Cooperativa;
