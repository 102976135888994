import React from 'react';
import './Home.css';
import Logo from '../imagens/Logo-Principal.png'

function Home() {
  return <div className='container'>
      <div className='vidro'>
        <div className='logo-principal'>
          <img src={Logo} width='600px' alt='Logo-Home'/>
        </div>
        <label className='slogan'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</label>
      </div>
    </div>;
}

export default Home;
