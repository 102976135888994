import React from 'react';
import Titulo from '../components/Titulos';
import Rodape from '../components/Rodapé'
import './Servicos.css'
import Foto1 from '../imagens/exemplo.png'
import Editavel from '../components/texto-editável';
import CustomizableBox from "../components/services_list";


function Servicos() {
  return <div className='container-serviços'>
    <div className='titulo_principal'>
      <Titulo titulo='NOSSOS SERVIÇOS'/>
    </div>
    <div className='profissionais'>
      <Editavel subtitulo="Nossos Profissionais" texto="     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." />
      <img src={Foto1} alt='Foto_exemplo' width='600px' height='500px'/>
    </div>    
    <span className='divisa'/>
    <div className='linha'>
    <CustomizableBox
      width="100%"
      height="100%"
        backgroundColor="#FFDE59"
        logo="Meu Logo"
        logoColor="#000"
        subtitulo="Subtítulo Exemplo"
        texto="Este é um exemplo de texto"
        botaoTexto="CONTRATAR"
        botaoDestino="/saiba-mais"
        botaoLargura="200px"
        botaoAltura="60px"
      />


      <CustomizableBox
      width="100%"
      height="100%"
        backgroundColor="#FFDE59"
        logo="Meu Logo"
        logoColor="#000"
        subtitulo="Subtítulo Exemplo"
        texto="Este é um exemplo de texto"
        botaoTexto="CONTRATAR"
        botaoDestino="/saiba-mais"

        botaoLargura="200px"
        botaoAltura="60px"
      />

    <CustomizableBox
      width="100%"
      height="100%"
      backgroundColor="#FFDE59"
      logo="Meu Logo"
      logoColor="#000"
      subtitulo="Subtítulo Exemplo"
      texto="Este é um exemplo de texto"
      botaoTexto="CONTRATAR"
      botaoDestino="/saiba-mais"
      botaoLargura="200px"
      botaoAltura="60px"
    />

    </div>
    <span className='divisa'/>
    <div className='frotas'>
      <img src={Foto1} alt='Foto_exemplo' width='600px' height='500px'/>
      <Editavel subtitulo="Nossas frotas" texto="     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." />
    </div>




<Rodape/>
</div>;
}

export default Servicos;
