import React from "react";
import { useNavigate } from "react-router-dom";
import "../components/cores.css";

const BotaoNavegacao = ({ texto, destino, imagem, largura = "150px", altura = "50px" }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(destino);
  };

  return (
    <button
      onClick={handleButtonClick}
      style={{
        ...styles.button,
        backgroundImage: `url(${imagem})`,
        width: largura,
        height: altura,
      }}
    >
      {texto}
    </button>
  );
};

const styles = {
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    borderRadius: "5px",
    color: "var(--amarelo)",
    border: "none",
    backgroundColor: "green",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
};

export default BotaoNavegacao;
