import React from 'react';
import './contatos.css';
import banner from '../imagens/bg.png';
import Botão from '../components/Botão'; // Ajuste o caminho conforme necessário

const Contato = () => {
    return (
        <div className="contato-container">
            <div className="contato-imagem">
                <img src={banner} alt="Contato" />
            </div>
            <div className="contato-formulario">
                <h2>Entre em Contato</h2>
                <form>
                    <div className="form-group">
                        <label htmlFor="nome">Nome</label>
                        <input type="text" id="nome" name="nome" placeholder="Seu nome" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">E-mail</label>
                        <input type="email" id="email" name="email" placeholder="Seu e-mail" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mensagem">Mensagem</label>
                        <textarea id="mensagem" name="mensagem" placeholder="Sua mensagem" rows="5" required></textarea>
                    </div>
                    {/* Substituindo o botão padrão pelo componente */}
                    <Botão texto="Enviar" tipo="submit" classe="btn-enviar" />
                </form>
            </div>
        </div>
    );
};

export default Contato;
