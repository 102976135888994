import React from 'react';
import Logo from '../imagens/logo-menu.png'

import './Rodape.css';  // Para estilos personalizados, se necessário

// Componente de Rodapé
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo da Empresa */}
        <div className="footer-logo">
          <img src={Logo} alt="Logo da Empresa" />
        </div>

        {/* Seção Inicio */}
        <div className="footer-section">
          <h4>Início</h4>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/sobre">Sobre</a></li>
            <li><a href="/servicos">Serviços</a></li>
          </ul>
        </div>

        {/* Seção Sobre */}
        <div className="footer-section">
          <h4>Sobre</h4>
          <ul>
            <li><a href="/sobre-a-empresa">Sobre a Empresa</a></li>
            <li><a href="/cooperados">Cooperados</a></li>
          </ul>
        </div>

        {/* Seção Suporte */}
        <div className="footer-section">
          <h4>Suporte</h4>
          <ul>
            <li><a href="mailto:support@empresa.com">Email</a></li>
            <li><a href="tel:+1234567890">Telefone</a></li>
          </ul>
        </div>

        {/* Seção Endereço */}
        <div className="footer-section">
          <h4>Endereço</h4>
          <ul>
            <li>Rua Exemplo, 123 - Cidade, Estado</li>
          </ul>
        </div>

          {/* Seção Contatos */}
        <div className="footer-section">
          <h4>Contatos</h4>
          <ul>
            <li>LOGOS</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
