import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Servicos from './pages/Servicos';
import Faca_parte from './pages/Faca_parte';
import Cooperativismo from './pages/Cooperativismo';
import Contatos from './pages/Contatos';




function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/servicos" element={<Servicos />} />
        <Route path="/faca_parte" element={<Faca_parte />} />
        <Route path="/Cooperativismo" element={<Cooperativismo />} />
        <Route path="/Contatos" element={<Contatos />} />
      </Routes>
    </Router>
  );
}

export default App;
